import validate from '../Validation/ValidateHelpers.js'

const updateFields = function updateFields(
  type,
  item,
  i,
  notifications,
  onChange
) {
  switch (type) {
    case 'service':
      exportFunctions.updateNotifications(
        onChange,
        notifications.map((n, ni) =>
          ni !== i ? n : { ...n, service: item?.value }
        )
      )
      break

    case 'contact':
      exportFunctions.updateNotifications(
        onChange,
        notifications.map((n, ni) =>
          ni !== i ? n : { ...n, contact: item.target.value }
        )
      )
      break

    case 'event_type':
      exportFunctions.updateNotifications(
        onChange,
        update_notification_object(item, i, notifications)
      )
      break

    case 'meta_data':
      exportFunctions.updateNotifications(
        onChange,
        notifications.map((n, ni) =>
          ni !== i ? n : { ...n, meta_data: item.target.value }
        )
      )
      break

    case 'pagerduty_client_name':
      exportFunctions.updateNotifications(
        onChange,
        notifications.map((n, ni) =>
          ni !== i
            ? n
            : {
                ...n,
                pagerduty: { ...n.pagerduty, client_name: item.target.value },
              }
        )
      )
      break

    case 'service_now_assignment_group':
      exportFunctions.updateNotifications(
        onChange,
        notifications.map((n, ni) =>
          ni !== i
            ? n
            : {
                ...n,
                service_now: {
                  ...n.service_now,
                  assignment_group: item.target.value,
                },
              }
        )
      )
      break

    case 'service_now_cmdb_ci':
      exportFunctions.updateNotifications(
        onChange,
        notifications.map((n, ni) =>
          ni !== i
            ? n
            : {
                ...n,
                service_now: { ...n.service_now, cmdb_ci: item.target.value },
              }
        )
      )
      break

    case 'service_now_service_offering':
      exportFunctions.updateNotifications(
        onChange,
        notifications.map((n, ni) =>
          ni !== i
            ? n
            : {
                ...n,
                service_now: {
                  ...n.service_now,
                  service_offering: item.target.value,
                },
              }
        )
      )
      break

    case 'excluded_dags':
      // [{ value: 'dag1', label: 'dag1' }, { value: 'dag2', label: 'dag2' }]
      exportFunctions.updateNotifications(
        onChange,
        notifications.map((n, ni) =>
          ni !== i
            ? n
            : { ...n, excluded_dags: item?.map(ix => ix.value) || [] }
        )
      )
      break

    case 'included_dags':
      // [ { dag_ids: ['dag1', 'dag2'], severity: 'S2' } ]
      exportFunctions.updateNotifications(
        onChange,
        notifications.map((n, ni) =>
          ni !== i ? n : { ...n, included_dags: item || [] }
        )
      )
      break
    default:
      return 'error'
  }
}

const updateNotifications = function updateNotifications(onChange, value) {
  let success = true

  try {
    onChange(value, validate.validateEntry(value))
  } catch (err) {
    console.log(err)
    success = false
  }
  return success
}

const update_notification_object = function update_notification_object(
  opt,
  i,
  notifications
) {
  try {
    return notifications.map((n, ni) =>
      ni !== i
        ? n
        : {
            ...n,
            event_type: opt.value,
            meta_data:
              opt.value.includes('dag') || opt.value.includes('operator')
                ? n.meta_data
                : '',
          }
    )
  } catch (err) {
    return false
  }
}

const managePagerDutyAttribute = function managePagerDutyAttribute(
  notification
) {
  if (notification.service && notification.service !== 'pagerduty') {
    if (notification.pagerduty) delete notification.pagerduty
  } else {
    if (!notification.pagerduty) {
      notification.pagerduty = {
        client_name: '',
      }
    }
  }

  return notification
}

const manageServiceNowAttribute = function manageServiceNowAttribute(
  notification
) {
  if (notification.service && notification.service !== 'service_now') {
    if (notification.service_now) delete notification.service_now
  } else {
    if (!notification.service_now) {
      notification.service_now = {
        assignment_group: '',
        cmdb_ci: '',
        service_offering: '',
      }
    }
  }

  return notification
}

const exportFunctions = {
  updateFields,
  updateNotifications,
  update_notification_object,
  managePagerDutyAttribute,
  manageServiceNowAttribute,
}

export default exportFunctions
